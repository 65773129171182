import React from 'react'
import Process1 from '../../img/process1.png'
import Process2 from '../../img/process2.png'
import Process3 from '../../img/process3.png'
import Process4 from '../../img/process4.png'

const DATA = [
    {
        title: 'Select Your Freight',
        img: Process1,
        content: 'The first step in our seamless shipping experience is selecting the freight you wish to send. We offer a variety of services to meet your diverse needs. Whether you require air freight for urgent deliveries, ocean freight for cost-effective transportation, or specialized services like vehicle freight, our user-friendly platform allows you to easily browse options and select the most suitable service for your shipment. Our team is also readily available to answer any questions and provide expert guidance to ensure you choose the perfect solution.'
    },
    {
        title: 'Generate Your Invoice',
        img: Process2,
        content: 'Once you have selected your desired freight service, we make it simple to create a transparent invoice. Our online system automatically calculates the cost based on your shipment details, including weight, dimensions, destination, and chosen service. This ensures you have a clear understanding of the associated fees upfront. Additionally, you can easily customize your invoice by specifying additional services or insurance options if needed. Our system provides detailed breakdowns of each cost component, allowing you to make informed decisions about your shipment.'
    },
    {
        title: 'Secure and Convenient Payment',
        img: Process3,
        content: 'We prioritize your security and offer a variety of secure payment methods to finalize your shipment. You can choose from trusted payment gateways to complete your transaction with peace of mind. Our system utilizes robust encryption technology to safeguard your financial information throughout the process. We also offer flexible payment options, allowing you to select the method that best suits your needs. Whether you prefer credit card payments, online banking transfers, or other secure options, we ensure a smooth and secure payment experience.'
    },
    {
        title: 'Fast and Reliable Delivery',
        img: Process4,
        content: 'Once your payment is confirmed, your shipment embarks on its journey to its destination. We are committed to providing fast and reliable delivery, utilizing established partnerships with leading carriers and optimized logistical networks. We offer real-time tracking capabilities that allow you to monitor your shipment\'s progress throughout its journey, keeping you informed and updated every step of the way.Our dedicated team is also available to address any concerns or questions you may have regarding your shipment\'s delivery.'
    }
]
function Content() {
    return (
        <div className='shipping'>
            {
                DATA.map((item, index) => <ShippingItem data={item} index={index} />)
            }
        </div>
    )
}

export default Content

const ShippingItem = (props) => {
    return (
        <div className="shipping__item" data-aos="fade-up">
            <div className="shipping__item__header">
                <p>{props.index + 1}</p>
                <h4>{props.data.title}</h4>
            </div>
            <div className="shipping__item__content">
                <p>{props.data.content}</p>
                <div className="shipping__item__content__img">
                    <img src={props.data.img} alt="Step Representation" />
                </div>
            </div>
        </div>
    )
}