import React from 'react'
import { useNavigate } from 'react-router-dom';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { isMobile } from 'react-device-detect';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const position = [51.505, -0.09]

function Content(props) {

    console.log(props)
    const navigate = useNavigate()

    return (
        <div className='mapcontent'>
            <div className="mapcontent__map">
                <MapContainer
                    style={{ height: 600, width: isMobile ?  window.innerWidth - 20 : window.innerWidth - 400 }}
                    center={[props.data.latitude, props.data.longitude]}
                    zoom={13}
                    scrollWheelZoom={false}
                    dragging={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Empress Shipment</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[props.data.latitude, props.data.longitude]}>
                        <Popup>
                            Current Location <br /> Last Set By Crew
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>
            <div className="mapcontent__btnsection">
                <button 
                    className='btn mapcontent__btn'
                    onClick={() => navigate('/track')}
                >
                    Back
                </button>
            </div>
        </div>
    )
}

export default Content