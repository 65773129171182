import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RiLockPasswordFill } from 'react-icons/ri'
import HeadImg from '../../img/harbor.png'
import { Spinner } from '../shared'
import { collection, getDocs, query, where, getFirestore } from "firebase/firestore";
import _ from 'lodash';
// import { ToastContainer, toast } from 'react-toastify';

function Content() {

    const navigate = useNavigate()
    const [tracking, setTracking] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)

    // const setError = (message) => toast.error(message, { position: 'top-center' })

    async function handleSubmit() {
        setShowSpinner(true)
        let result = []
        try {

            const db = getFirestore()
            const usersRef = collection(db, "tracking")
            const q1 = query(usersRef, where("tracking", "==", tracking));
            const querySnapshot = await getDocs(q1);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                result.push({ id: doc.id, ...doc.data() })
            });

            if (!_.isEmpty(result)) {

                setTimeout(() => {
                    setShowSpinner(false)
                    navigate('/package', { state: result[0] })
                }, 3000);

            } else {

                setShowSpinner(false)
                alert('Wrong tracking number')
            }

        } catch (error) {
            console.log(error)
            setShowSpinner(false)
            alert('Error Fetching Package')
            // setError('Wrong tracking number')
        }
    }

    return (
        <div className='track'>

            {
                showSpinner ?
                    <Spinner showSpinner={showSpinner} width={window.innerWidth} /> :
                    <div className="track__form">
                        <div className="track__form__header">
                            <img src={HeadImg} alt="Ship on sea" />
                            <h4>Enter your tracking number</h4>
                        </div>


                        <div className="track__form__inputbox">
                            <label htmlFor="track">Tracking Number</label>
                            <div className="track__form__input__wrap">
                                <div className="track__form__input__icon__box">
                                    <RiLockPasswordFill className="track__form__input__icon" />
                                </div>
                                <input
                                    type="text"
                                    className='track__form__input'
                                    name="track"
                                    id="track"
                                    value={tracking}
                                    onChange={(e) => setTracking(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="track__form__btnsection">
                            <button
                                className="btn track__form__btn"
                                onClick={() => handleSubmit()}
                            >
                                Submit
                            </button>
                        </div>
                        {/*<ToastContainer />*/}
                    </div>
            }


        </div>
    )
}

export default Content