import React from 'react'
import About1 from '../../img/port1.jpg'
import About2 from '../../img/port2.jpg'
import About3 from '../../img/port3.jpg'

function About() {
    return (
        <section className="section-about">
            <div className="u-center-text u-margin-bottom-big">
                <h2 className="heading-secondary">
                    Exciting Shipping Adventures
                </h2>
            </div>

            <div className="row" data-aos="fade-up">
                <div className="col-1-of-2 small-margin-right">
                    <h3 className="heading-tertiary u-margin-bottom-small">Your cargo, our commitment</h3>
                    <p className="paragraph">
                        Delivered on time, every time. With precision and care,
                        we navigate the global landscape to ensure your shipments reach their destination seamlessly.
                        Your trusted partner in international shipping.
                        We specialize in transporting goods across borders, providing tailored solutions to meet your unique needs

                    </p>

                    <h3 className="heading-tertiary u-margin-bottom-small">Connecting the world, one shipment at a time. </h3>
                    <p className="paragraph">
                        With an extensive network of global partners, we offer comprehensive shipping services that span continents and borders.
                        Our expertise in customs clearance, documentation, and logistics ensures your cargo arrives safely and on schedule.
                    </p>

                    <btn className="btn">Learn more &rarr;</btn>
                </div>
                <div className="col-1-of-2">
                    <div className="composition">
                        {
                                /*
                            
                            <img srcset="img/nat-1.jpg 300w, img/nat-1-large.jpg 1000w"
                                 sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                                 alt="Photo 1"
                                 class="composition__photo composition__photo--p1"
                                 src="img/nat-1-large.jpg"
                            />

                            <img srcset="img/nat-2.jpg 300w, img/nat-2-large.jpg 1000w"
                                 sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                                 alt="Photo 2"
                                 class="composition__photo composition__photo--p2"
                                 src="img/nat-2-large.jpg"
                            />

                            <img srcset="img/nat-3.jpg 300w, img/nat-3-large.jpg 1000w"
                                 sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                                 alt="Photo 3"
                                 class="composition__photo composition__photo--p3"
                                 src="img/nat-3-large.jpg"
                            />
                            
                            */}

                        <img src={About1} alt="Rep 1" className="composition__photo composition__photo--p1" />
                        <img src={About2} alt="Rep 2" className="composition__photo composition__photo--p2" />
                        <img src={About3} alt="Rep 3" className="composition__photo composition__photo--p3" />

                    </div>
                </div>
            </div>
        </section>
    )
}

export default About