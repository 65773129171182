import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import Service1 from '../../img/ocean.jpg'
import Service2 from '../../img/air.jpg';
import Service3 from '../../img/vehicle.jpg'

function Services() {
    return (
        <div className='services'>

            <div className="u-center-text u-margin-bottom-small">
                <h2 className='heading-secondary'>OUR SERVICES</h2>
            </div>
            <p className='services__intro'>Our experienced team provides reliable and efficient shipping services to meet your global logistics needs</p>

            <div className="services__body">
                <ServicesItem
                    img={Service1}
                    title="OCEAN FREIGHT SHIPPING"
                    text="Most common method of transporting goods over long distances.
                        It's a cost-effective and reliable option whether you're shipping raw materials, finished products, or bulk commodities."
                />

                <ServicesItem
                    img={Service2}
                    title="AIR FREIGHT SHIPPING"
                    text="Fastest and most efficient way to transport goods across long distances. It's ideal for time-sensitive shipments, high-value products, and perishable items."
                />

                <ServicesItem
                    img={Service3}
                    title="CAR SHIPPING"
                    text="Convenient and efficient way to transport your vehicle across long distances. Whether you're relocating, selling, or buying a car, this service offers a hassle-free solution."
                />

            </div>
        </div>
    )
}

export default Services

const ServicesItem = (props) => {
    return (
        <div className="services__item" data-aos="flip-left">

            <div className="services__item__img">
                <img src={props.img} alt="Rep of service" />
            </div>

            <h4>{props.title}</h4>

            <div className="services__item__text">
                {props.text}
            </div>

            <div className="services__item__bottom">
                <div className="services__item__bottom__read">
                    <p>Read More</p>
                    <IoIosArrowForward />
                </div>

                <div className="services__item__bottom__add">
                    <IoMdAdd />
                </div>
            </div>
        </div>
    )
}