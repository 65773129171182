import React from 'react'
import Process1 from '../../img/process1.png'
import Process2 from '../../img/process2.png'
import Process3 from '../../img/process3.png'
import Process4 from '../../img/process4.png'

function Process() {
    return (
        <div className='process'>
            <div className="u-center-text u-margin-bottom-small">
                <h2 className='heading-secondary'>OUR PROCEDURE</h2>
            </div>


            <div className="process__intro">
                Our commitment to excellence drives us to deliver exceptional shipping solutions worldwide.
            </div>

            <div className="process__body" data-aos="fade-up">
                <div className="process__item">
                    <div className="process__item__number">1</div>
                    <div className="process__item__img">
                        <img src={Process1} alt="Process Rep" />
                    </div>
                    <p>SELECT FREIGHT</p>
                </div>

                <div className="process__item__divider" />

                <div className="process__item">
                    <div className="process__item__number">2</div>
                    <div className="process__item__img">
                        <img src={Process2} alt="Process Rep" />
                    </div>
                    <p>CREATE INVOICE</p>
                </div>

                <div className="process__item__divider" />

                <div className="process__item">
                    <div className="process__item__number">3</div>
                    <div className="process__item__img">
                        <img src={Process3} alt="Process Rep" />
                    </div>
                    <p>SECURE PAYMENT</p>
                </div>

                <div className="process__item__divider" />

                <div className="process__item">
                    <div className="process__item__number">4</div>
                    <div className="process__item__img">
                        <img src={Process4} alt="Process Rep" />
                    </div>
                    <p>FAST DELIVERY</p>
                </div>
            </div>
        </div>
    )
}

export default Process