import React from 'react'
import { useNavigate } from 'react-router-dom';
import { RiUserReceived2Fill, RiUserSharedFill } from "react-icons/ri";
import { TfiExport, TfiImport } from "react-icons/tfi";
import { CiCalendarDate } from "react-icons/ci";
import { MdDateRange, MdEmail, MdPhone } from "react-icons/md";
import { FaWeightScale } from "react-icons/fa6";
import { SlOptionsVertical } from "react-icons/sl";

function Content(props) {

    const navigate = useNavigate()

    return (
        <div className="package">
            <div className="package__content">
                <div className="package__header">
                    View Package Details
                </div>
                <ul>
                    
                    <li>
                        <RiUserReceived2Fill />
                        <div className="package__details">
                            <h4>Receiver Name</h4>
                            <p>{props.data.receiverName}</p>
                        </div>
                    </li>
                    <li>
                        <MdPhone />
                        <div className="package__details">
                            <h4>Receiver Phone</h4>
                            <p>{props.data.receiverPhone}</p>
                        </div>
                    </li>
                    <li>
                        <MdEmail />
                        <div className="package__details">
                            <h4>Receiver Email</h4>
                            <p>{props.data.receiverEmail}</p>
                        </div>
                    </li>
                    <li>
                        <RiUserSharedFill />
                        <div className="package__details">
                            <h4>Sender Name</h4>
                            <p>{props.data.senderName}</p>
                        </div>
                    </li>
                    <li>
                        <MdPhone />
                        <div className="package__details">
                            <h4>Sender Phone</h4>
                            <p>{props.data.senderPhone}</p>
                        </div>
                    </li>
                    <li>
                        <MdEmail />
                        <div className="package__details">
                            <h4>Sender Email</h4>
                            <p>{props.data.senderEmail}</p>
                        </div>
                    </li>
                    <li>
                        <TfiExport />
                        <div className="package__details">
                            <h4>Dispatch Port</h4>
                            <p>{props.data.dispatchPort}</p>
                        </div>
                    </li>
                    <li>
                        <TfiImport />
                        <div className="package__details">
                            <h4>Destination Port</h4>
                            <p>{props.data.destinationPort}</p>
                        </div>
                    </li>
                    <li>
                        <CiCalendarDate />
                        <div className="package__details">
                            <h4>Dispatch Date</h4>
                            <p>{props.data.dispatchDate}</p>
                        </div>
                    </li>
                    <li>
                        <MdDateRange />
                        <div className="package__details">
                            <h4>Arrival Date</h4>
                            <p>{props.data.arrivalDate}</p>
                        </div>
                    </li>

                    <li>
                        <FaWeightScale />
                        <div className="package__details">
                            <h4>Weight in Kilograms</h4>
                            <p>{props.data.weight}</p>
                        </div>
                    </li>

                    <li>
                        <SlOptionsVertical />
                        <div className="package__details">
                            <h4>Shipping method</h4>
                            <p>Ocean Freight</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="package__btnsection">
                <button 
                    className='btn package__btn'
                    onClick={() => navigate('/livetracking', {state: {latitude: props.data.latitude, longitude: props.data.longitude}})}
                >
                    Live Tracking
                </button>
            </div>
        </div>
    )
}

export default Content