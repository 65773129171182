import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { Home } from "./features/Home";
import { Map } from "./features/Map";
import { Package } from "./features/Package";
import { Services } from "./features/Services";
import { Shipping } from "./features/Shipping";
import { Track } from "./features/Track";

function Navigator() {
  return (
    <Router>
      <Routes>
        <Route path="/track" element={<Track />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/services" element={<Services />} />
        <Route path="/package" element={<Package />} />
        <Route path="/livetracking" element={<Map />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default Navigator;
