import Navigator from "./Navigator";
import "./sass/main.scss";
import "rodal/lib/rodal.css";
import "aos/dist/aos.css";
import AOS from "aos";

function App() {
  AOS.init();
  return (
    <div className="App">
      <Navigator />
    </div>
  );
}

export default App;
