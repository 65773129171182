import React from 'react'
import videobgmp4 from '../../img/headervid.mp4'
import poster from '../../img/poster.jpeg'

function VideoBack() {
    return (
        <div className='landing__video'>
            <div className='landing__video__wrapper'>
                <div className="landing__video__container">
                    <video
                        role="presentation"
                        preload="auto"
                        playsinline=""
                        crossorigin="anonymous"
                        loop muted autoplay=""
                        className="landing__video__vid"
                        poster={poster}
                    >
                        {/*<source src={videobgwebm} type="video/webm" />*/}
                        <source src={videobgmp4} type="video/mp4" />
                    </video>
                    <div className="landing__video__text">
                        <div className="landing__video__text__main">
                            Your shipment, <span>our mission</span>
                        </div>
                        <div className="landing__video__text__sub">
                            Shipping solutions at the speed of business
                        </div>
                        <div className="landing__video__btnsection">
                            <button className='btn landing__video__btn'>Learn More</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoBack