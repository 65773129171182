import React from 'react'

function Landing() {
    return (
      <div className='page__landing'>
          <p className='page__landing__main'>Track your <span>Package</span> online</p>
          <p className='page__landing__sub'>Enter your code below</p>
      </div>
    )
  }
export default Landing