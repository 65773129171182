import React, { useState } from 'react'
import { Sidebar, Footer, Partners, Help,Header } from '../shared'
import VideoBack from './VideoBack'
import About from './About'
import Services from './Services'
import Process from './Process'
import Quote from './Quote'
import Journey from './Journey'
import Reviews from './Reviews'


function Home() {

    const [showHelp, setShowHelp] = useState(false)

    return (
        <div className='page'>
            <Sidebar setShowHelp={setShowHelp} showHelp={showHelp}/>
            <Header setShowHelp={setShowHelp}/>
            <div className="page__main">
                {
                    showHelp && 
                    <Help 
                        showModal={showHelp}
                        hideModal={() => setShowHelp(false)}
                    />
                }
                <VideoBack />
                <About />
                <Services />
                <Process />
                <Quote />
                <Journey />
                <Reviews />
                <Partners />
                <Footer />
            </div>
        </div>
    )
}

export default Home