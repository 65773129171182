import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Sidebar, Footer, Partners, Header, Help } from '../shared'
import Landing from './Landing'
import Content from './Content'

function Package() {
    const location = useLocation()
    const [showHelp, setShowHelp] = useState(false)

    const data = location.state

    return (
        <div className='page'>
            <Sidebar setShowHelp={setShowHelp} showHelp={showHelp} />
            <Header setShowHelp={setShowHelp} />
            <div className="page__main">
                {
                    showHelp &&
                    <Help
                        showModal={showHelp}
                        hideModal={() => setShowHelp(false)}
                    />
                }
                <Landing />
                <Content data={data}/>
                <Partners />
                <Footer />
            </div>
        </div>
    )
}

export default Package