import React from 'react'
import { SyncLoader } from 'react-spinners';
import { isMobile } from 'react-device-detect';
function Spinner(props) {

  return (
    <div class="spinner" style={props.showSpinner ? {height: props.height || '70vh', width: isMobile ? props.width : '100%'} : { height: '0' }}>
      <SyncLoader color='#333399' loading={props.showSpinner} size={isMobile ? props.width/4.5 :90} margin={10} />
    </div>
  )

}

export default Spinner