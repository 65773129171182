import React, { useEffect, useRef, useState } from 'react'
import Partner1 from '../../img/partner1.png'
import Partner2 from '../../img/partner2.png'
import Partner3 from '../../img/partner3.png'
import Partner4 from '../../img/partner4.png'
import Partner5 from '../../img/partner5.png'
import Partner6 from '../../img/partner6.png'
import Partner7 from '../../img/partner7.png'
import Partner8 from '../../img/partner8.png'
import Partner9 from '../../img/partner9.png'
import Partner10 from '../../img/partner10.png'

function Partners() {


  return (
    <div className='partners'>

      <div class="u-center-text u-margin-bottom-medium">
        <h2 className='heading-secondary'>OUR PARTNERS</h2>
      </div>

      <div className="partners__body slideshow" data-aos="fade-up">
        <img src={Partner1} alt="Partner logo" />
        <img src={Partner2} alt="Partner logo" />
        <img src={Partner3} alt="Partner logo" />
        <img src={Partner4} alt="Partner logo" />
        <img src={Partner5} alt="Partner logo" />
        <img src={Partner6} alt="Partner logo" />
        <img src={Partner7} alt="Partner logo" />
        <img src={Partner8} alt="Partner logo" />
        <img src={Partner9} alt="Partner logo" />
        <img src={Partner10} alt="Partner logo" />
      </div>
    </div>
  )
}

export default Partners