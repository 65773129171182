import React from 'react'

function Landing() {
  return (
    <div className='page__landing'>
        <p className='page__landing__main'>Want an <span>experience</span> with us</p>
        <p className='page__landing__sub'>Follow these steps</p>
    </div>
  )
}

export default Landing