import React from 'react'
import Logo from '../../img/logo.png'


function Footer() {
    return (
        <footer className="footer">
            <div className="footer__logo-box">

                <img className="footer__logo" alt="Full logo" src={Logo} />

            </div>
            <div className="row">


                <div className="col-1-of-3 small-margin-right">
                    <p className="footer__header">Popular Services</p>
                    <p className="footer__copyright">
                        <ul className="footer__list">
                            <li>Car Shipping</li>
                            <li>Container Shipping</li>
                            <li>RoRo Shipping</li>
                            <li>Groupage Shipping</li>
                            <li>Haulage Shipping</li>
                            <li>Household & Personal effects</li>
                        </ul>
                    </p>
                </div>

                <div className="col-1-of-3 small-margin-right">
                    <p className="footer__header">Destinations</p>
                    <p className="footer__copyright">
                        <ul className="footer__list">
                            <li>Japan</li>
                            <li>Canada</li>
                            <li>UK</li>
                            <li>China</li>
                            <li>Vietnam</li>
                            <li>United States</li>
                            <li>New Zealand</li>
                        </ul>
                    </p>
                </div>

                <div className="col-1-of-3">
                    <p className="footer__header">Contact Us</p>
                    <p className="footer__copyright">
                        <ul className="footer__list">
                            <li>Call Us on +233 (0) 531332564</li>
                            <li>Whasapp +233531332564</li>
                            <li><a style={{ color: 'green' }} href="mailto:support@empressshipping.live">Email us: support@empressshipping.live</a></li>
                            <li>Locate Us at 42 Pavilion Drive, Tema  Off Quarshie Teyeman, P. O. Box 107128, Ghana</li>
                        </ul>
                    </p>
                </div>


            </div>
        </footer>
    )
}

export default Footer