import React from 'react'

function Landing() {
  return (
    <div className='page__landing'>
        <p className='page__landing__main'>Navigate our <span>quality</span> services</p>
        <p className='page__landing__sub'>Choose from the best</p>
    </div>
  )
}

export default Landing