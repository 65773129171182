import React from 'react'
import { GiBookPile, GiBackup } from "react-icons/gi";
import { FaUsers, FaShippingFast } from "react-icons/fa";

function Journey() {
    return (
        <div className='journey'>

            <div className="u-center-text u-margin-bottom-medium">
            <h2 className='heading-secondary'>JOURNEY SO FAR</h2>
            </div>

            <div className="journey__intro">
                Over the years, we've partnered with a diverse range of clients on exciting projects,
                forging strong relationships built on trust, collaboration, and a shared commitment to excellence.
                Our journey has been marked by successful deliveries, innovative solutions, and a continuous drive to exceed expectations
            </div>

            <div className="journey__body" data-aos="fade-up">

                <div className="journey__item">
                    <div className="journey__item__icon">
                        <GiBookPile />
                    </div>
                    <div className="journey__item__content">
                        <span>627</span> Projects Done
                    </div>
                </div>

                <div className="journey__item">
                    <div className="journey__item__icon">
                        <FaUsers />
                    </div>
                    <div className="journey__item__content">
                        <span>263</span> Permanent Clients
                    </div>
                </div>

                <div className="journey__item">
                    <div className="journey__item__icon">
                        <FaShippingFast />
                    </div>
                    <div className="journey__item__content">
                        <span>103</span> Owned Vehicles
                    </div>
                </div>

                <div className="journey__item">
                    <div className="journey__item__icon">
                        <GiBackup />
                    </div>
                    <div className="journey__item__content">
                        <span>58</span> Support Member
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Journey