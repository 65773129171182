import React, { useState } from 'react'
import { Sidebar, Footer, Partners, Header, Help } from '../shared'
import Landing from './Landing'
import Content from './Content'
// PAGE TO BRING EVERYTHING TOGETHER
function Services() {
  const [showHelp, setShowHelp] = useState(false)

  return (
    <div className='page'>
      <Sidebar setShowHelp={setShowHelp} showHelp={showHelp} />
      <Header setShowHelp={setShowHelp} />
      <div className="page__main">
        {
          showHelp &&
          <Help
            showModal={showHelp}
            hideModal={() => setShowHelp(false)}
          />
        }

        <Landing />
        <Content />
        <Partners />
        <Footer />
      </div>
    </div>
  )
}

export default Services