import React from 'react'

function Quote() {
    return (
        <section class="section-book">

            <div class="u-margin-bottom-medium u-center-text">
                <h2 class="heading-secondary">
                    Request Quote
                </h2>
            </div>

            <div class="row" data-aos="fade-up">
                <div class="book">
                    <div class="book__form">
                        <form action="#" class="form">

                            <div class="form__group">
                                <input type="text" class="form__input" placeholder="Full name" id="name" required />
                                <label for="name" class="form__label">Full name</label>
                            </div>

                            <div class="form__group">
                                <input type="email" class="form__input" placeholder="Email address" id="email" required />
                                <label for="email" class="form__label">Email address</label>
                            </div>

                            <div class="form__group">
                                <input type="number" class="form__input" placeholder="Contact" id="contact" required />
                                <label for="contact" class="form__label">Contact</label>
                            </div>

                            <div class="form__group">
                                <textarea class="form__input" placeholder="Please state more details about the enquiry here" id="details" required />
                                <label for="details" class="form__label">Enquiry Details</label>
                            </div>



                            <div class="form__group">
                                <button class="btn btn--basic">Submit &rarr;</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Quote