import React from 'react'

function Landing() {
  return (
    <div className='page__landing'>
        <p className='page__landing__main'>View Your <span>Package</span> Details</p>
        <p className='page__landing__sub'>Report any issues</p>
    </div>
  )
}

export default Landing