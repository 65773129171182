import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Logo from '../../img/logo.png'
import { ImMenu } from "react-icons/im";
import { MdHome, MdContactSupport } from "react-icons/md";
import { GiClubs } from "react-icons/gi";
import { RiShip2Fill } from "react-icons/ri";
import { FaMapLocation } from "react-icons/fa6";

const inlineStyle = {
    display: 'flex',
    width: '100%'
}

function Header(props) {

    const [showDD, setShowDD] = useState(false)
    const navigate = useNavigate()

    function navigateTo(path) {
        setShowDD(false)
        navigate(path)
    }

    function showHelp() {
        setShowDD(false)
        props.setShowHelp(true)
    }

    return (
        <div className='header'>
            <div className="header__main">
                <div className="header__main__img">
                    <img src={Logo} alt="Logo of Company" />
                </div>

                <div
                    className="header__main__menu"
                    onClick={() => setShowDD(!showDD)}
                >
                    <ImMenu />
                </div>
            </div>
            <div
                className="header__dropdown"
                style={showDD ? inlineStyle : {}}
            >
                <ul>
                    <li
                        onClick={() => navigateTo('/')}
                    >
                        <MdHome />
                        <span>Home</span>
                    </li>
                    <li
                        onClick={() => navigateTo('/services')}
                    >
                        <GiClubs />
                        <span>Services</span>
                    </li>
                    <li
                        onClick={() => navigateTo('/shipping')}
                    >
                        <RiShip2Fill />
                        <span>Shipping</span>
                    </li>
                    <li
                        onClick={() => navigateTo('/track')}
                    >
                        <FaMapLocation />
                        <span>Track</span>
                    </li>
                    <li onClick={() => showHelp()}>
                        <MdContactSupport />
                        <span>Contact Us</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Header