import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { MdHome, MdContactSupport } from "react-icons/md";
import { GiClubs } from "react-icons/gi";
import { RiShip2Fill } from "react-icons/ri";
import { FaMapLocation } from "react-icons/fa6";
import Logo from '../../img/logo.png'

const ROUTES = ['/', '/services', '/shipping', '/track']


function Sidebar(props) {

    const navigate = useNavigate()
    const location = useLocation();
    const route = location.pathname
    console.log(route)
    return (
        <nav className="sidebar">

            <ul className="side-nav">

                <div className="sidebar__logo">

                    <img alt="Full logo" src={Logo} />

                </div>

                <li
                    className={`side-nav__item ${route === ROUTES[0] ? 'side-nav__item--active' : ''}`}
                    onClick={() => navigate('/')}
                >
                    <p className="side-nav__link">
                        <MdHome className="side-nav__icon" />
                        <span>Home</span>
                    </p>
                </li>
                <li
                    className={`side-nav__item ${route === ROUTES[1] ? 'side-nav__item--active' : ''}`}
                    onClick={() => navigate('/services')}
                >
                    <p className="side-nav__link">
                        <GiClubs className="side-nav__icon" />
                        <span>Services</span>
                    </p>
                </li>
                <li
                    className={`side-nav__item ${route === ROUTES[2] ? 'side-nav__item--active' : ''}`}
                    onClick={() => navigate('/shipping')}
                >
                    <p className="side-nav__link">
                        <RiShip2Fill className="side-nav__icon" />
                        <span>Shipping</span>
                    </p>
                </li>
                <li
                    className={`side-nav__item ${route === ROUTES[3] ? 'side-nav__item--active' : ''}`}
                    onClick={() => navigate('/track')}
                >
                    <p className="side-nav__link">
                        <FaMapLocation className="side-nav__icon" />
                        <span>Track</span>
                    </p>
                </li>

                <li
                    className="side-nav__item"
                    onClick={() => props.setShowHelp(!props.showHelp)}
                >
                    <p className="side-nav__link">
                        <MdContactSupport className="side-nav__icon" />
                        <span>Contact Us</span>
                    </p>
                </li>
            </ul>

            <div className="sidebar__legal">
                <p> &copy; 2017 by Empressgroup. All rights reserved</p>
            </div>
        </nav>
    )
}

export default Sidebar