import React from 'react'

function Landing() {
  return (
    <div className='page__landing'>
        <p className='page__landing__main'>Current <span>Location</span> of Package</p>
        <p className='page__landing__sub'>Refelcts Last Set By Crew</p>
    </div>
  )
}

export default Landing