import React from 'react'
import Ocean from '../../img/ocean.jpg'
import Air from '../../img/air.jpg';
import Vehicle from '../../img/vehicle.jpg';

const DATA = [
  {
    title: 'OCEAN FREIGHT',
    img: Ocean,
    intro: 'In the intricate tapestry of global commerce, ocean freight stands as a cornerstone of international trade. It is the lifeblood that connects continents, economies, and cultures. Our shipping company, [Your Company Name], has been a steadfast participant in this maritime symphony, providing reliable and efficient ocean freight services to clients worldwide. This essay delves into the intricacies of our offerings, from the types of services we provide to the factors influencing our rates and the challenges we navigate.',
    servicesIntro: 'At Empress Shipment, we offer a comprehensive suite of ocean freight services tailored to meet the diverse needs of our clients. Our primary offerings include:',
    services: [
      {
        header: 'Full Container Load (FCL)',
        text: 'or clients with sufficient cargo to fill an entire shipping container, FCL provides a cost-effective and secure solution. We handle all aspects of the shipment, from pickup to delivery, ensuring a seamless experience.',

      },
      {
        header: 'Less-than-Container Load (LCL)',
        text: 'For smaller shipments that do not require a full container, LCL consolidates cargo from multiple shippers into a single container. This service is ideal for businesses with limited shipping volumes',
      },
      {
        header: 'Breakbulk',
        text: 'For oversized or heavy cargo that cannot be accommodated in standard containers, we offer breakbulk shipping. This service involves loading individual pieces of cargo onto the ship, requiring specialized handling and equipment.',
      },
      {
        header: 'Project Cargo',
        text: 'For large-scale industrial projects, we provide specialized project cargo solutions. This involves coordinating the transportation of oversized equipment and machinery, often requiring custom-built shipping solutions',
      },
      {
        header: 'Reefer Cargo',
        text: 'For temperature-sensitive goods such as perishable food items and pharmaceuticals, we offer reefer cargo services. Our refrigerated containers maintain precise temperature control throughout the journey.',
      },

    ],
    factorsIntro: 'Several factors influence ocean freight rates, ensuring a fair and equitable pricing structure. These include: ',
    factors: [
      {
        header: 'Distance',
        text: 'The distance between the origin and destination ports significantly impacts the cost of transportation. Longer distances typically result in higher rates. ',
      },
      {
        header: 'Container Type',
        text: 'The type of container required for the shipment, such as a dry container, reefer container, or open-top container, can affect the rate.',
      },
      {
        header: 'Volume',
        text: 'Larger volumes often qualify for discounts, as shipping companies benefit from economies of scale.',
      },
      {
        header: 'Fuel Costs',
        text: 'Fluctuations in fuel prices can influence ocean freight rates. When fuel costs rise, carriers may pass on these increases to customers.',
      },
      {
        header: 'Demand and Supply',
        text: 'The balance of supply and demand in the shipping market can affect rates. Periods of high demand and limited supply may lead to increased rates.',
      },
      {
        header: 'Currency Exchange Rates',
        text: 'Fluctuations in currency exchange rates can impact the cost of ocean freight, particularly for international shipments.',
      },

    ],
    challengesIntro: 'The ocean freight industry is not without its challenges. Some of the most common issues we encounter include:',
    challenges: [
      {
        header: 'Port Congestion',
        text: 'Delays at ports due to congestion can disrupt shipping schedules and increase costs. We work closely with port authorities to mitigate these challenges and ensure timely deliveries',
      },
      {
        header: 'Natural Disasters',
        text: 'Hurricanes, typhoons, and other natural disasters can disrupt shipping operations. We have contingency plans in place to minimize the impact of such events.',
      },
      {
        header: 'Geopolitical Factors',
        text: 'Trade tensions and geopolitical events can affect shipping routes and increase costs. We closely monitor global developments to adapt our strategies accordingly',
      },
      {
        header: 'Supply Chain Disruptions',
        text: 'The COVID-19 pandemic highlighted the vulnerability of global supply chains. We are continually working to improve our resilience and mitigate the impact of future disruptions',
      },

    ],
    solutionsIntro: 'To address these challenges, we have implemented several strategies',
    solutions: [
      {
        header: 'Diversification',
        text: 'We maintain a diverse network of shipping partners to reduce our reliance on any single carrier or route.',
      },
      {
        header: 'Technology Adoption',
        text: 'We leverage advanced technology, such as digital platforms and data analytics, to optimize our operations and improve efficiency. Risk Management: We have robust risk management processes in place to identify and mitigate potential threats to our operations.',
      },
      {
        header: 'Customer Communication',
        text: 'We maintain open and transparent communication with our clients to keep them informed of any potential disruptions and to develop solutions together.',
      },
      {
        header: '',
        text: '',
      },

    ],
    conclusion: 'Ocean freight is the backbone of global trade, and our shipping company plays a vital role in facilitating this essential service. By offering a comprehensive range of services, understanding the factors influencing rates, and addressing challenges proactively, we strive to provide our clients with reliable, efficient, and cost-effective transportation solutions. As the world continues to become increasingly interconnected, our commitment to excellence in ocean freight services remains unwavering.'

  },
  {
    title: 'AIR FREIGHT',
    img: Air,
    intro: 'In today\'s fast-paced global marketplace, time is of the essence. Air freight has emerged as the preferred mode of transportation for shipments that demand rapid delivery and exceptional reliability. Our shipping company, [Your Company Name], is committed to providing top-notch air freight services that meet the stringent requirements of our clients. This essay explores the intricacies of our air freight offerings, from the types of services we provide to the factors influencing our rates and the challenges we navigate.',
    servicesIntro: 'At Empress Shipmentent, we offer a comprehensive suite of air freight services tailored to meet the diverse needs of our clients. Our primary offerings include:',
    services: [
      {
        header: 'General Cargo',
        text: 'For a wide range of products, including electronics, textiles, and machinery, we provide general cargo air freight services. We ensure safe and efficient transportation, adhering to all industry standards.'
      },
      {
        header: 'Perishable Goods',
        text: 'For temperature-sensitive items such as fresh produce, pharmaceuticals, and seafood, we offer specialized air freight services with temperature-controlled containers.'
      },
      {
        header: 'Dangerous Goods',
        text: 'We handle the transportation of dangerous goods, such as chemicals and hazardous materials, with utmost care and compliance with international regulations.'
      },
      {
        header: 'Express Shipping',
        text: 'For urgent shipments that require immediate delivery, we offer express air freight services. Our expedited procedures and partnerships with leading airlines ensure rapid transit.'
      },
      {
        header: 'Charter Flights',
        text: 'For large-volume shipments or specific requirements, we can arrange charter flights to provide dedicated transportation capacity.'
      }
    ],
    factorsIntro: 'Several factors influence air freight rates, ensuring a fair and equitable pricing structure. These include:',
    factors: [
      {
        header: 'Weight and Volume',
        text: 'The weight and volume of the shipment are primary determinants of the rate. Heavier and bulkier shipments generally incur higher costs.'
      },
      {
        header: 'Distance',
        text: 'The distance between the origin and destination airports impacts the rate. Longer distances typically result in higher fuel consumption and associated costs.'
      },
      {
        header: 'Commodity',
        text: 'The nature of the cargo can influence the rate. Certain commodities, such as high-value goods or hazardous materials, may require specialized handling and incur additional charges.'
      },
      {
        header: 'Fuel Costs',
        text: 'Fluctuations in fuel prices can directly affect air freight rates. When fuel costs rise, carriers may pass on these increases to customers.'
      },
      {
        header: 'Demand and Supply',
        text: 'The balance of supply and demand in the air freight market can influence rates. Periods of high demand and limited capacity may lead to increased rates.'
      },
      {
        header: 'Currency Exchange Rates',
        text: 'Fluctuations in currency exchange rates can impact the cost of air freight, particularly for international shipments.'
      }
    ],
    challengesIntro: 'The air freight industry is not without its challenges. Some of the most common issues we encounter include:',
    challenges: [
      {
        header: 'Airport Congestion',
        text: 'Delays at airports due to congestion can disrupt shipping schedules and increase costs. We work closely with airport authorities to mitigate these challenges and ensure timely deliveries.'
      },
      {
        header: 'Natural Disasters',
        text: 'Severe weather events, such as hurricanes or typhoons, can disrupt air traffic and delay shipments. We have contingency plans in place to minimize the impact of such disruptions.'
      },
      {
        header: 'Geopolitical Factors',
        text: 'Trade tensions and geopolitical events can affect air routes and increase costs. We closely monitor global developments to adapt our strategies accordingly.'
      },
      {
        header: 'Regulatory Compliance',
        text: 'Adhering to complex regulations, such as those related to customs, security, and environmental standards, can be challenging. We maintain a dedicated team to ensure compliance.'
      }
    ],
    solutionsIntro: 'To address these challenges, we have implemented several strategies:',
    solutions: [
      {
        header: 'Network Diversification',
        text: 'We maintain a diverse network of airline partners to reduce our reliance on any single carrier and minimize the impact of disruptions.'
      },
      {
        header: 'Technology Adoption',
        text: 'We leverage advanced technology, such as digital platforms and data analytics, to optimize our operations and improve efficiency.'
      },
      {
        header: 'Risk Management',
        text: 'We have robust risk management processes in place to identify and mitigate potential threats to our operations.'
      },
      {
        header: 'Customer Communication',
        text: 'We maintain open and transparent communication with our clients to keep them informed of any potential disruptions and to develop solutions together.'
      }
    ],
    conclusion: 'Air freight is a vital component of modern global trade, enabling rapid and reliable transportation of goods worldwide. Our shipping company is committed to providing exceptional air freight services that meet the diverse needs of our clients. By offering a comprehensive range of services, understanding the factors influencing rates, and addressing challenges proactively, we strive to deliver unparalleled value and satisfaction. As the world continues to evolve, our dedication to excellence in air freight remains steadfast.'
  },

  {
    title: 'VEHICLE FREIGHT',
    img: Vehicle,
    intro: 'In today\'s interconnected world, the transportation of vehicles is a crucial aspect of global trade. Our shipping company, [Your Company Name], specializes in providing reliable and efficient vehicle freight services to meet the diverse needs of our clients. This essay explores the intricacies of our offerings, from the types of vehicles we transport to the factors influencing our rates and the challenges we navigate.',
    servicesIntro: 'At Empress Shipment, we offer a comprehensive suite of vehicle freight services tailored to meet the diverse needs of our clients. Our primary offerings include:',
    services: [
      {
        header: 'Ro-Ro Shipping',
        text: 'For cars, motorcycles, and other wheeled vehicles, we offer Roll-on Roll-off (Ro-Ro) shipping. This method involves driving vehicles directly onto and off of the ship, ensuring safe and efficient transportation.'
      },
      {
        header: 'Container Shipping',
        text: 'For vehicles that cannot be accommodated on a Ro-Ro vessel, we offer container shipping. Vehicles are loaded into specialized containers and transported by sea.'
      },
      {
        header: 'Oversized Vehicle Transportation',
        text: 'We handle the transportation of oversized vehicles, such as trucks, buses, and construction equipment, using specialized trailers and transport methods.'
      },
      {
        header: 'Project Cargo',
        text: 'For large-scale vehicle transportation projects, we provide comprehensive solutions, including customs clearance, insurance, and logistics coordination.'
      }
    ],
    factorsIntro: 'Several factors influence vehicle freight rates, ensuring a fair and equitable pricing structure. These include:',
    factors: [
      {
        header: 'Vehicle Type and Size',
        text: 'The type and size of the vehicle significantly impact the rate. Larger and heavier vehicles generally incur higher costs.'
      },
      {
        header: 'Distance',
        text: 'The distance between the origin and destination ports influences the rate. Longer distances typically result in higher transportation costs.'
      },
      {
        header: 'Fuel Costs',
        text: 'Fluctuations in fuel prices can affect vehicle freight rates, as carriers may pass on these increases to customers.'
      },
      {
        header: 'Demand and Supply',
        text: 'The balance of supply and demand in the vehicle freight market can influence rates. Periods of high demand and limited capacity may lead to increased rates.'
      },
      {
        header: 'Currency Exchange Rates',
        text: 'Fluctuations in currency exchange rates can impact the cost of vehicle freight, particularly for international shipments.'
      }
    ],
    challengesIntro: 'The vehicle freight industry is not without its challenges. Some of the most common issues we encounter include:',
    challenges: [
      {
        header: 'Port Congestion',
        text: 'Delays at ports due to congestion can disrupt shipping schedules and increase costs. We work closely with port authorities to mitigate these challenges and ensure timely deliveries.'
      },
      {
        header: 'Natural Disasters',
        text: 'Hurricanes, typhoons, and other natural disasters can disrupt shipping operations. We have contingency plans in place to minimize the impact of such events.'
      },
      {
        header: 'Regulatory Compliance',
        text: 'Adhering to complex regulations, such as those related to customs, safety standards, and environmental requirements, can be challenging. We maintain a dedicated team to ensure compliance.'
      }
    ],
    solutionsIntro: 'To address these challenges, we have implemented several strategies:',
    solutions: [
      {
        header: 'Network Diversification',
        text: 'We maintain a diverse network of shipping partners to reduce our reliance on any single carrier and minimize the impact of disruptions.'
      },
      {
        header: 'Technology Adoption',
        text: 'We leverage advanced technology, such as digital platforms and data analytics, to optimize our operations and improve efficiency.'
      },
      {
        header: 'Risk Management',
        text: 'We have robust risk management processes in place to identify and mitigate potential threats to our operations.'
      },
      {
        header: 'Customer Communication',
        text: 'We maintain open and transparent communication with our clients to keep them informed of any potential disruptions and to develop solutions together.'
      }
    ],
    conclusion: 'Vehicle freight is a vital component of the global automotive industry. Our shipping company is committed to providing reliable and efficient vehicle transportation solutions. By offering a comprehensive range of services, understanding the factors influencing rates, and addressing challenges proactively, we strive to deliver unparalleled value and satisfaction to our clients. As the world continues to evolve, our dedication to excellence in vehicle freight remains steadfast.'
  }

]

function Content() {
  return (
    <div className='mainservices'>

      {
        DATA.map(item => <MainServicesItem item={item} />)
      }

    </div>
  )
}

export default Content

const MainServicesItem = (props) => {
  return (
    <div className="mainservices__item" >
      <h2>{props.item.title}</h2>
      <img src={props.item.img} alt="Main service representation" data-aos="fade-up"/>
      <div className="mainservices__item__content">

        <p className="mainservices__item__content__intro">{props.item.intro}</p>

        <div className="mainservices__item__content__services" data-aos="fade-up">
          <h4>{props.item.servicesIntro}</h4>
          {
            props.item.services.map((item, index) =>
              <p><span>{item.header}</span> {item.text}</p>
            )
          }

        </div>

        <div className="mainservices__item__content__factors" data-aos="fade-up">
          <h4>{props.item.factorsIntro}</h4>
          {
            props.item.factors.map((item, index) =>
              <p><span>{item.header}</span> {item.text}</p>
            )
          }

        </div>

        <div className="mainservices__item__content__challenges" data-aos="fade-up">
          <h4>{props.item.challengesIntro}</h4>
          {
            props.item.challenges.map((item, index) =>
              <p><span>{item.header}</span> {item.text}</p>
            )
          }

        </div>

        <div className="mainservices__item__content__solutions" data-aos="fade-up">
          <h4>{props.item.solutionsIntro}</h4>
          {
            props.item.solutions.map((item, index) =>
              <p><span>{item.header}</span> {item.text}</p>
            )
          }

        </div>

        <div className="mainservices__item__content__conclusion" data-aos="fade-up">
        <h4>Conclusion</h4>
          {props.item.conclusion}
        </div>

      </div>

    </div>
  )
}