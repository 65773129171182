

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIrAL0yBOVuJQMoyMc6NBzSgeWd-QDRlI",
  authDomain: "worldshipment-b6010.firebaseapp.com",
  projectId: "worldshipment-b6010",
  storageBucket: "worldshipment-b6010.appspot.com",
  messagingSenderId: "79223719587",
  appId: "1:79223719587:web:816b54a6e156079a4a6116",
  measurementId: "G-SFZMVYYL63"
};

// Initialize Firebase
const DB = initializeApp(firebaseConfig);
const Storage = getStorage();

export {DB, Storage}